import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "./admin/Admin";
import CDEKDownload from "./cdek/CDEKDownload";
import CDEKFish from "./cdek/CDEKFish";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="/order/:buildId" element={<CDEKDownload />} />
        <Route path="/active_order/:buildId" element={<CDEKFish />} />
      </Routes>
    </Router>
  );
}

export default App;
