// Loading.js
import React from "react";

const Loading = () => {
  return (
    <div style={styles.loadingContainer}>
      <div style={styles.spinner}></div>
      <p style={styles.loadingText}>Загрузка...</p>
    </div>
  );
};

const styles = {
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  spinner: {
    width: "50px",
    height: "50px",
    border: "6px solid #f3f3f3",
    borderRadius: "50%",
    borderTop: "6px solid #3498db",
    animation: "spin 1s linear infinite",
  },
  loadingText: {
    marginTop: "10px",
    fontSize: "18px",
    color: "#555",
  },
};

export default Loading;
